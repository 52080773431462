a,
u {
    text-decoration: none;
}

.app-body-container {
    margin-top: 64px;
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    .app-body-container {
        margin-top: 0px;
    }
}