.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .notes p {
  color: #666;
}

.border {
    border: 1px solid rgba(224, 224, 224, 1);
}

.pagebreak {
    page-break-before: always;
}
.Login {
    text-align: center;
    padding: 20px;
}

.Login form {
    margin: 0 auto;
    max-width: 500px;
}
.Signup {
    text-align: center;
    padding: 20px;
}

.Signup form {
    margin: 0 auto;
    max-width: 500px;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
}
a,
u {
    text-decoration: none;
}

.app-body-container {
    margin-top: 64px;
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    .app-body-container {
        margin-top: 0px;
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

